import http from '../http-common';

export default class BreadcrumbsDataServices{
    async GetBreadcrumbsTrainingPage(courseId, trainingId ){
        return await http.get(`v1/breadcrumbs/course/${courseId}/training/${trainingId}`);
    }

    async GetBreadcrumbsTraining(courseId){
        return await http.get(`v1/breadcrumbs/course/${courseId}`);
    }
}