<template>
  <div class="content items-center">
    <Mural
      class="q-mt-md"
      :breadcrumbs="breadcrumbs"
      route="open-question-correction"
      :query="{
        quiz_id: quiz_id,
        title: title,
        course_id: course_id,
        training_id: training_id,
        post_id: post_id,
      }"
    ></Mural>
    <section class="row justify-between q-gutter-md q-my-md">
      <div class="container-card col q-px-xl">
        <h2 class="information-label">Curso</h2>
        <p class="information">
          {{ course_title }}
        </p>
      </div>
      <div class="container-card col q-px-xl">
        <h2 class="information-label">Aluno</h2>
        <p class="information">{{ student_name }}</p>
      </div>
      <div class="container-card col q-px-xl">
        <h2 class="information-label">Nota</h2>
        <p class="information">{{ score }}/{{ maxScore }}</p>
      </div>
    </section>
    <section
      class="q-mx-xl flex column no-wrap scroll container-correction"
      style="height: 500px"
    >
      <h1 class="title text-weight-bold text-left">Questões abertas</h1>
      <q-form @submit.prevent.stop="sendCorrection" class="q-gutter-md">
        <div>
          <div v-for="(question, index) in questions" :key="index">
            <FormCorrection
              :question="question"
              :index="index"
              @sendCorrection="
                (score, quiz_student_answers_id) => {
                  scores[index] = score;
                }
              "
              @sendTeacherConsideration="
                (value) => {
                  teachersConsiderations[index] = value;
                }
              "
              @sendCurrentCorrection="
                (value) => {
                  currentCorrection[index] = value;
                }
              "
            ></FormCorrection>
          </div>
          <div class="q-mr-xl q-my-xl row justify-end">
            <q-btn
              class="cancel-btn q-mr-md"
              label="Cancelar"
              rounded
              no-caps
              :to="{
                name: 'open-question-correction',
                query: {
                  quiz_id: quiz_id,
                  title: title,
                  course_id: course_id,
                  training_id: training_id,
                  post_id: post_id,
                },
              }"
            />
            <FormButton :titleButton="'Salvar correção'" submit />
          </div>
        </div>
      </q-form>
    </section>
  </div>
</template>

<script>
/* Components */
import Mural from "@/components/Mural.vue";
import FormCorrection from "@/components/courses/training/evaluation/FormCorrection.vue";
import FormButton from "@/components/admin/buttons/FormButton.vue";

/* Vue */
import { ref, onMounted } from "vue";

/* VUEROUTER */
import { useRoute, useRouter } from "vue-router";

/* Services */
import TrailDataServices from "@/services/TrailDataServices";
import BreadcrumbsDataServices from "@/services/BreadcrumbsDataServices";

/* Utils */
import AlertsClass from "@/utils/Notifications/AlertsClass";

export default {
  name: "Correction",
  components: {
    Mural,
    FormCorrection,
    FormButton,
  },
  setup(props) {
    /* CONSTANTS */
    const route = new useRoute();
    const router = new useRouter();
    const _TrailDataServices = new TrailDataServices();
    const _BreadcrumbsDataServices = new BreadcrumbsDataServices();
    const Alerts = new AlertsClass();
    const questions = ref([]);
    const corrections = ref([]);
    const teachersConsiderations = ref([]);
    const scores = ref([]);
    const currentCorrection = ref([]);
    const course_title = ref("");

    /* VARIABLES */
    let quiz_id = route.query.quizId;
    let title = route.query.title;
    let training_id = route.query.trainingId;
    let course_id = route.query.courseId;
    let post_id = route.query.postId;
    let student_name = route.query.studentsName;
    let quizStudent_id = route.query.quizStudentId;
    let score = parseFloat(route.query.score).toFixed(2);
    let maxScore = parseFloat(route.query.maxScore).toFixed(2);
    let breadcrumbs = ref([`Correção - ${title}`]);
    /* FUNCTIONS */
    async function _getQuizById() {
      _TrailDataServices
        .getQuizById(quizStudent_id)
        .then((response) => {
          questions.value = response.data;
        })
        .catch((error) => {});
    }

    async function courseTitle() {
      await _BreadcrumbsDataServices
        .GetBreadcrumbsTrainingPage(course_id, training_id)
        .then((response) => {
          course_title.value = response.data;
        });
    }

    async function sendCorrection() {
      for (let index = 0; index < questions.value.length; index++) {
        let score = Number(scores.value[index]);
        let teacherConsideration = teachersConsiderations.value[index];

        if (questions.value[index].revised == true) {
          corrections.value.push({
            Score: score || currentCorrection.value[index].Score,
            QuizStudentAnswersId:
              questions.value[index].quiz_student_answers_id,
            TeacherConsideration:
              teacherConsideration ||
              currentCorrection.value[index].TeacherConsideration,
          });
        } else {
          corrections.value.push({
            Score: score ?? currentCorrection.value[index].Score,
            QuizStudentAnswersId:
              questions.value[index].quiz_student_answers_id,
            TeacherConsideration:
              teacherConsideration ||
              currentCorrection.value[index].TeacherConsideration,
          });
        }
      }

      await _TrailDataServices
        .correctionOpenQuestion(corrections.value)
        .then((response) => {
          if (response.status === 200) {
            _TrailDataServices.scoreQuiz(course_id, training_id, quiz_id);
            router.push({
              name: "open-question-correction",
              query: {
                quiz_id: quiz_id,
                course_id: course_id,
                training_id: training_id,
                post_id: post_id,
                title: title,
              },
            });
          }
        })
        .catch((error) => {
          Alerts.alertOf(
            "Erro ao enviar correção, por favor tente novamente em outro momento!"
          );
          while (corrections.value.length > 0) {
            corrections.value.pop();
          }
        });
    }

    onMounted(() => {
      _getQuizById();
      courseTitle();
    });
    return {
      /* VARIABLES */
      breadcrumbs,
      title,
      quiz_id,
      post_id,
      course_id,
      training_id,
      score,
      student_name,
      maxScore,
      /* CONSTANTS */
      scores,
      questions,
      teachersConsiderations,
      corrections,
      currentCorrection,
      course_title,
      /* FUNCTIONS */
      sendCorrection,
    };
  },
};
</script>

<style lang="scss" scoped>
.content {
  overflow: auto;
  height: 95%;
  display: flex;
  flex-direction: column;
  padding: 0 24px !important;
}

.title {
  font-size: 1.25rem;
}

.cancel-btn {
  color: var(--q-primary, #fe4e64);
  padding: 8px 15px;

  ::before {
    box-shadow: none;
  }
}

button span {
  padding: 2px;
  border-radius: 50%;
  width: 25px;
}

.information-label {
  font-size: 1rem;
  letter-spacing: -0.25px;
  color: #1a1a1a;
  font-weight: 600;
}

.information {
  font-size: 0.75rem;
  color: #808080;
  letter-spacing: -0.25px;
  font-weight: 500;
}

.container-card {
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
}

.container-correction {
  width: 90%;
}
</style>
