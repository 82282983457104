<template>
  <div>
    <q-expansion-item
      expand-separator
      expand-icon-toggle
      switch-toggle-side
      class="q-mt-md"
      :header-style="{ width: '100%', fontWeight: 'bold' }"
      :default-opened="index == 0"
    >
      <template v-slot:header>
        <div class="flex items-baseline" style="width: 100%">
          <h2>Questão {{ index + 1 }}</h2>
          <div class="q-ml-md flex items-baseline">
            <label class="q-mr-xs">nota</label>
            <q-input
              dense
              v-model.number="scoreQuestion"
              step="any"
              placeholder="0.00"
              input-class="text-center"
              filled
              @update:model-value="$emit('sendCorrection', scoreQuestion)"
              :rules="[
                (val) =>
                  (val !== null && val !== '') || 'A nota não pode ser vazia!',
                (val) =>
                  val <= questionMaxScore ||
                  `A nota não pode ser maior que ${questionMaxScore}`,
              ]"
            />
          </div>
          <div class="q-ml-md flex items-baseline">
            <label class="q-mr-xs">Valor da questão</label>
            <q-input
              dense
              :placeholder="weight"
              type="number"
              filled
              readonly
            />
          </div>
        </div>
      </template>
      <div class="q-px-md q-mt-xs">
        <p><strong>Pergunta:</strong> {{ question.description_question }}</p>
        <p class="answer">
          <strong>Resposta do aluno:</strong> {{ question.answer }}
        </p>
        <div>
          <label class="correction" for="">Correção da questão</label>
          <q-input
            v-model="teacherConsideration"
            type="textarea"
            @update:model-value="
              $emit('sendTeacherConsideration', teacherConsideration)
            "
            outlined
            class="q-mt-md"
            :rules="[(val) => !!val || 'A correção não pode ser vazia!']"
          />
        </div>
      </div>
    </q-expansion-item>
  </div>
</template>

<script>
import { ref, computed, onMounted } from "vue";

export default {
  name: "FormCorrection",
  props: {
    question: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  setup(props, { emit }) {
    /* CONSTANTS */
    const score = props.question.question_score;
    const review = props.question.review;
    const quiz_student_answers_id = props.question.quiz_student_answers_id;

    const scoreQuestion = ref(score);
    const teacherConsideration = ref(review);

    /* COMPUTEDS */
    const weight = computed(() => props.question.question_score_max);
    const questionMaxScore = computed(() => props.question.question_score_max);

    onMounted(() => {
      emit("sendCurrentCorrection", {
        Score: score,
        TeacherConsideration: review,
      });
    });

    return {
      /* COMPUTEDS */
      weight,
      questionMaxScore,
      /* CONSTANTS */
      scoreQuestion,
      teacherConsideration,
    };
  },
  emits: [
    "sendCorrection",
    "sendTeacherConsideration",
    "sendCurrentCorrection",
  ],
};
</script>

<style scoped>
h2 {
  font-size: 0.875rem;
  font-weight: bold;
}

label {
  color: #808080;
  letter-spacing: -0.25px;
  font-weight: 500;
  font-size: 0.875rem;
}

p {
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 20px;
  width: 90%;
  color: #666666;
}

p.answer {
  font-weight: 500;
  color: #a6a6a6;
}

.correction {
  font-weight: 600;
}
</style>
